<template>
  <div>
    <div v-if="isResetPassword" class="row card-container ">
      <div class="col">
        <form class="form card card-body responsive">
          <h1>{{ $t("resetPassword.default.passwordResetHeader") }}</h1>
          <div class="form-group mx-auto">
            <label class="inputEmail" for="inputEmail">{{ $t("resetPassword.default.labelEmail") }}</label>
            <input id="inputEmail" v-model="email" class="form-control" type="email">
            <div v-show="errors['email']" id="err_inputEmail" class="form-error">{{ errors['email'] }}</div>
          </div>
          <p class="mt-3 mb-0 mx-auto">{{ $t("resetPassword.default.allowEmails") }}
          </p>

          <button class="btn btn-primary ok btn-outline-light font-weight-bold mx-auto m-t-20" type="button"
                  v-on:click="sendMail">{{ $t("resetPassword.default.send") }}
          </button>

          <button @click="login" class="btn btn-light bg-white ashen-link text-decoration-none mt-2 ml-2 btn-outline-light">
            <a href="javascript:void(0);" class="text-decoration-none">{{ $t("resetPassword.default.cancel") }}</a>
          </button>
        </form>
      </div>
    </div>
    <div v-else class="row second-row card-container">
      <div class="col">
        <form class="form card card-body responsive">
          <h1 class="font-20 mb-0">{{ $t("resetPassword.emailSent.successMessage") }}</h1>
          <div class="form-group m-b-5">
            <p class="text-left color-33" v-html="$t('resetPassword.emailSent.resetPasswordFromEmail')">
            </p>
          </div>
          <button id="okButton" class="btn btn-primary font-weight-bold mx-auto" type="button" v-on:click="login">
            {{ $t("resetPassword.emailSent.btnReturnToLogin") }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Common from '../assets/jsipclient/common'
import {SetResetPassword} from '../assets/jsipclient/index'

export default {
  name: "ResetPassword",
  data() {
    return {
      email: '',
      isResetPassword: true,
      errors: []
    }
  },
  methods: {
    login() {
      this.$router.push({name: 'Login'});
    },
    sendMail() {
      this.errors = [];
      const api = Common.createLoginApi()

      const setResetPasswordFinish = new SetResetPassword()
      setResetPasswordFinish.email = this.email
      api.loginResetPasswordPostAsync(setResetPasswordFinish)
          .then(() => {
            this.isResetPassword = false
          })
          .catch((error) => {
            Common.getErrorList(error.response, this.errors)
          })
    }
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
  }
}
</script>

<style lang="scss" scoped>

</style>
